




















































































































































































































@import "../../../../assets/css/exper/experiDetail";

.addForm {
  margin-top: 16px;

  .el-form-item:nth-child(2) {
    margin-bottom: 10px;
  }
}
